import React from 'react';
import classNames from 'classnames';
import { OnVisible } from 'components';
import * as styles from './styles.module.scss';

const RichText = ({ html, h1, h2, h3, h4, h5, h6, p1, p2, p3, className, onVisible }) => {
  if (!html || html.length < 7) return null;

  const combinedClassNames = classNames(styles.text, {
    [className]: className,
    [styles.h1]: h1,
    [styles.h2]: h2,
    [styles.h3]: h3,
    [styles.h4]: h4,
    [styles.h5]: h5,
    [styles.p1]: p1,
    [styles.p2]: p2,
    [styles.p3]: p3,
  });

  if (onVisible) {
    return <OnVisible className={combinedClassNames} dangerouslySetInnerHTML={{ __html: html }} />;
  }

  return <div className={combinedClassNames} dangerouslySetInnerHTML={{ __html: html }} />;
};

export default RichText;
