import React from 'react';
import { Logo, Section, Link, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <Section as="footer" className={styles.footer} containerClassName={styles.container}>
      <Logo />
      <OnVisible className={styles.nav}>
        <Link to="tel:+61404110648">M: 0404 110 648</Link>
        <Link to="mailto:jl@ldstudio.com.au">E: jl@ldstudio.com.au</Link>
        <Link to="https://goo.gl/maps/wAbjnSiDcopGics17">HQ: 85 William Street, Darlinghurst NSW 2010</Link>
        <span>© {year} Copyright</span>
      </OnVisible>
    </Section>
  );
};

export default Footer;
