import React from 'react';
import { Section, Image, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const TwoUp = (props) => {
  const { data } = props;
  const { items, slice_type: sliceName } = data;
  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName={sliceName} spacing="s">
      {items.map((item) => (
        <OnVisible key={item?.image?.url} className={styles.imageContainer} threshold={0.1}>
          <Image image={item?.image} />
        </OnVisible>
      ))}
    </Section>
  );
};

export default TwoUp;
