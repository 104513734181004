import React from 'react';
import { Section, Image, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const OneUp = (props) => {
  const { data } = props;
  const { primary, slice_type: sliceName } = data;

  const { image } = primary;

  if (!image) return null;

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName={sliceName} spacing="s">
      <OnVisible className={styles.imageContainer} threshold={0.1}>
        <Image className={styles.image} image={image} />
      </OnVisible>
    </Section>
  );
};

export default OneUp;
