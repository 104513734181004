module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/wm-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://ldstudio.com.au"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lombardo Design Studio - Architecture & Interior Design","short_name":"PWA Name","description":"Lombardo Design Studio is a Building Design firm located in Sydney offering expertise across disciplines in Architecture, Interior Design and Project Management.","start_url":"/","background_color":"#1A1919","theme_color":"#1A1919","display":"standalone","icon":"src/images/icon.png","include_favicon":false,"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"a5decb0d22d8ca45f1b0ad8c3f795121"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"navigateFallbackWhitelist":[{}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
