import React, { useState, useEffect } from 'react';
import { Image, Section, Link } from 'components';
import * as styles from './styles.module.scss';

const ProjectNext = ({ project }) => {
  const [isMobile, setIsMobile] = useState();

  useEffect(() => {
    const { width } = window.screen;
    setIsMobile(width <= 660);
  }, []);

  const { image, name, url } = project;

  const nextText = name?.text;

  const exitConfig = {
    trigger: ({ node }) => {
      const container = document.querySelector('.next-project-container');
      container.style.maxHeight = '100vh';
      setTimeout(function () {
        const documentHeight = node.scrollHeight;
        console.log('document.body.scrollHeight', documentHeight);
        window.scrollTo({ top: documentHeight, behavior: 'smooth' });
      }, 10);
    },
    length: 1,
  };

  const entryConfig = {
    delay: isMobile ? 0.4 : 1,
    state: { image },
  };

  return (
    <Section className={styles.section} containerClassName={`${styles.container} next-project-container`}>
      <Image className={`${styles.image} next-project-image`} image={image} alt={nextText} />
      <Link useTransition exit={exitConfig} entry={entryConfig} to={url} className={styles.content} />
    </Section>
  );
};

export default ProjectNext;
