import React from 'react';
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews';
import { Link } from 'components';

import linkResolver from '../../../config/linkResolver';
import Page from '../../templates/page';

const PrismicPreviewProviderWrapper = (props) => {
  const { element } = props;
  return (
    <PrismicPreviewProvider
      internalLinkComponent={({ href, ...linkProps }) => <Link to={href} {...linkProps} />}
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
          linkResolver,
          componentResolver: componentResolverFromMap({
            page: Page,
          }),
        },
      ]}
    >
      {element}
    </PrismicPreviewProvider>
  );
};

export default PrismicPreviewProviderWrapper;
