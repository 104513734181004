import React from 'react';
import { Image } from 'components';
import * as styles from './styles.module.scss';

const isDev = process.env.NODE_ENV === 'development';

const Section = (props) => {
  const { as, className, containerClassName, children, sliceName, noContainer, bgImage, spacing } = props;

  const HtmlElement = as || 'section';

  const spacingClassNames = {
    s: styles.smallSpacing,
    m: styles.mediumSpacing,
    l: styles.largeSpacing,
  };

  return (
    <HtmlElement
      className={`${styles.section} ${spacingClassNames[spacing || 'm']} ${className || ''}`}
      data-slice={isDev ? sliceName : null}
    >
      {bgImage && <Image image={bgImage} className={styles.sectionBg} />}
      {noContainer ? children : <div className={`${styles.container} ${containerClassName || ''}`}>{children}</div>}
    </HtmlElement>
  );
};

export default Section;
