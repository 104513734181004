import React from 'react';
import { Section, RichText, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const OneUp = (props) => {
  const { data } = props;
  const { primary, slice_type: sliceName } = data;

  const { content } = primary;

  const contentHtml = content?.html;

  if (!contentHtml || contentHtml.length < 7) return null;

  return (
    <Section containerClassName={styles.container} sliceName={sliceName} spacing="s">
      <RichText onVisible className={styles.content} html={contentHtml} />
    </Section>
  );
};

export default OneUp;
