import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import './styles.scss';

const Image = (props) => {
  const { image, alt, className = '', id, imgStyle, ...otherProps } = props;
  if (!image) return <div className={`gatsby-image placeholder ${className}`} />;

  if (image?.gatsbyImageData) {
    return (
      <GatsbyImage
        className={`gatsby-image ${className}`}
        id={id || null}
        image={image?.gatsbyImageData}
        alt={image.alt || alt || ''}
        imgStyle={imgStyle}
        {...otherProps}
      />
    );
  }

  if (image.url) {
    return (
      <div className={`gatsby-image ${className}`} id={id || null}>
        <img src={image.url} alt={image.alt || alt || ''} style={imgStyle} />
      </div>
    );
  }

  return <div className={`gatsby-image placeholder ${className}`} id={id || null} />;
};

export default Image;
