import React from 'react';
import { Link } from 'components';
import { siteName } from '../../../config/website';
import * as styles from './styles.module.scss';
import LogoSvg from '../../images/logo.svg';

const Logo = ({ className }) => (
  <Link className={`${styles.logo} ${className || ''}`} to="/">
    <img src={LogoSvg} alt={siteName} width="382" height="27" />
  </Link>
);

export default Logo;
