import React from 'react';
import { Section, ProjectCard } from 'components';
import * as styles from './styles.module.scss';

const ProjectGrid = (props) => {
  const { data } = props;
  const { items, slice_type: sliceName } = data;

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName={sliceName} spacing="s">
      {items.map((item) => (
        <ProjectCard {...item} key={item?.project?.url} />
      ))}
    </Section>
  );
};

export default ProjectGrid;
