import React, { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { Footer, Header, SEO, NoJs, WmAscii } from 'components';
import 'typeface-lato';
import '../../sass/global/styles.scss';
import './styles.scss';

const Layout = (props) => {
  const { children, seo, location, fixedHeader } = props;

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <>
      <NoJs />
      <WmAscii />
      <Header fixedHeader={fixedHeader} />
      <main>
        <SEO {...seo} location={location} />
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
