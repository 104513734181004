import React from 'react';
import { Link, Section, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const ProjectDetails = (props) => {
  const { location, status, type } = props;

  const metaItems = [
    {
      name: 'Location',
      value: location,
    },
    {
      name: 'Status',
      value: status,
    },
    {
      name: 'Type',
      value: type,
    },
  ];

  const populatedMetaItems = metaItems.filter((item) => item.value);

  return (
    <Section className={styles.section} containerClassName={styles.container} spacing="s">
      <OnVisible className={styles.meta}>
        {populatedMetaItems.map((item) => (
          <div key={item.value} className={styles.item}>
            <span className={styles.title}>{item.name}</span>
            <span className={styles.value}>{item.value}</span>
          </div>
        ))}
      </OnVisible>
    </Section>
  );
};

export default ProjectDetails;
