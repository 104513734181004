const generatePath = require('./generatePath');

module.exports = (doc) => {
  const { uid } = doc;
  // Example of link resolver for other post type
  // --------------------------------------------
  if (doc.type === 'project') {
    return `/project${generatePath(uid)}`;
  }
  return generatePath(uid);
};
