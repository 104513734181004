import React from 'react';
import { Image, Link, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const ProjectCard = ({ project }) => {
  if (!project?.document?.data) {
    return null;
  }
  const {
    document: { data: projectData },
    url,
  } = project;

  const { image, homepage_image: homepageImage, location, name } = projectData;
  const locationText = location?.text;
  const nameText = name?.text;

  return (
    <OnVisible className={styles.card} key={url} threshold={0.1}>
      <Image image={homepageImage?.url ? homepageImage : image} className={styles.image} />
      <Link className={styles.content} to={url} useTransition>
        <OnVisible as="h2" className={styles.title}>
          {nameText}
        </OnVisible>
        <div className={styles.meta}>
          <OnVisible as="span" className={styles.location}>
            {locationText}
          </OnVisible>
        </div>
      </Link>
    </OnVisible>
  );
};

export default ProjectCard;
