import React, { useEffect, useRef } from 'react';
import { Image, Section, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const ProjectHeader = (props) => {
  const { image, name } = props;
  const timeout = useRef(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    timeout.current = setTimeout(() => {
      document.body.style.overflow = 'auto';
      document.body.style.position = 'relative';
    }, 1200);
    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.position = 'relative';
      clearTimeout(timeout.current);
    };
  }, []);

  return (
    <Section className={styles.section} containerClassName={styles.container}>
      <Image className={styles.image} image={image} alt={name} />
      {name && (
        <div className={styles.content}>
          <OnVisible as="h1" className={styles.title}>
            {name}
          </OnVisible>
          {/* <ScrollDown /> */}
        </div>
      )}
    </Section>
  );
};

export default ProjectHeader;
