module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Lombardo Design Studio - Architecture & Interior Design', // Default Site Title used for SEO & PWA
  description:
    'Lombardo Design Studio is a Building Design firm located in Sydney offering expertise across disciplines in Architecture, Interior Design and Project Management.', // Default Site Decription used for SEO
  siteName: 'Website Name', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/open-graph.jpg', // Default OpenGraph image
  ogLanguage: 'en_AU', // Facebook Language
  socialLinks: ['https://www.instagram.com/lombardodesign', 'https://www.pinterest.com.au/LDSpinterest'], // Array of social links (facebook, insta, etc)
  // JSONLD / Manifest
  icon: 'src/images/icon.png', // Used for manifest favicon, splash screen, and icon generation
  shortName: 'PWA Name', // shortname for manifest. MUST be shorter than 12 characters
  author: 'PWA Author', // Author for schemaORGJSONLD
  themeColor: '#1A1919', // PWA Icon background & address bar colour if installed on desktop
  backgroundColor: '#1A1919', // PWA colour shown before styles and content loads, should match the background-color CSS property in the site’s stylesheet

  twitter: '', // Twitter Username
};
