import React from 'react';
import { Logo, Section, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const Header = ({ fixedHeader }) => (
  <Section
    as="header"
    className={`${styles.header} ${fixedHeader ? styles.fixedHeader : ''}`}
    containerClassName={styles.container}
  >
    <OnVisible className={styles.logoContainer}>
      <Logo />
    </OnVisible>
  </Section>
);

export default Header;
