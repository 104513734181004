import React from 'react';
import * as styles from './styles.module.scss';

const ScrollDown = () => (
  <div className={styles.container}>
    <div className={styles.line} />
  </div>
);

export default ScrollDown;
