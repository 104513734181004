import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import './styles.scss';

/* eslint react/destructuring-assignment: 0 */
const Carousel = (props) => {
  const { children, containerClassName, sliderRef, settings } = props;

  // Pass sliderRef to parent component
  const sliderElement = useRef(null);

  useEffect(() => {
    if (sliderRef) {
      sliderRef(sliderElement);
    }
  }, [sliderRef]);

  return (
    <div className={`carousel ${containerClassName || ''}`}>
      <Slider {...settings} ref={sliderRef ? sliderElement : null}>
        {children}
      </Slider>
    </div>
  );
};

export default Carousel;
